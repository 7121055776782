body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ListName {
  font-family: "Helvetica Neue";
  font-weight: 300;
  font-size: 30px;
  text-align: center;
  color: #373a3c;
}

.ListDepartmentName {
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  color: #373a3c;
}

.ListDay {
  font-family: "Helvetica Neue";
  font-weight: normal;
  font-size: 22px;
  text-align: center;
  color: #000;
}

.textLink {
  font-size: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.transparent {
  display: inline;
  margin-top: 10px;
  margin-left: 10px;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 0.2;
  transition: opacity 0.5s;
}

.transparent:hover {
  display: inline;
  margin-top: 10px;
  margin-left: 10px;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 1;
  transition: opacity 0.5s;
}

.active {
  display: inline;
  margin-top: 10px;
  margin-left: 10px;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 1;
  transition: opacity 0.5s;
}

.ModalAlert2 {
  margin-top: 100px;
  position: fixed;
  top: 0;
}

.generalLoading {
  color: white;
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.subdivLoading {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.textLoading {
  text-align: center;
  color: black;
  font-size: 40px;
  margin: 20px;
}

