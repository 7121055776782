.ListName {
  font-family: "Helvetica Neue";
  font-weight: 300;
  font-size: 30px;
  text-align: center;
  color: #373a3c;
}

.ListDepartmentName {
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  color: #373a3c;
}

.ListDay {
  font-family: "Helvetica Neue";
  font-weight: normal;
  font-size: 22px;
  text-align: center;
  color: #000;
}

.textLink {
  font-size: 22px;
  text-decoration-line: underline;
}
