.generalLoading {
  color: white;
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.subdivLoading {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.textLoading {
  text-align: center;
  color: black;
  font-size: 40px;
  margin: 20px;
}
