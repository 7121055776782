.transparent {
  display: inline;
  margin-top: 10px;
  margin-left: 10px;
  justify-content: center;
  opacity: 0.2;
  transition: opacity 0.5s;
}

.transparent:hover {
  display: inline;
  margin-top: 10px;
  margin-left: 10px;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.5s;
}

.active {
  display: inline;
  margin-top: 10px;
  margin-left: 10px;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.5s;
}
